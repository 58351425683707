import React from "react"

const blurb = 'Trusted visibility into load statuses, truck count, wellsite inventory, and operational status.'

const PartnerSection = () => {
  return (
    <div className="p-8 items-center bg-[rgb(198,200,202)] text-[rgb(247,247,247)]">
      <div className="mb-2 flex justify-center gap-2">
        {[...Array(5)].map(() => <i class="ri-star-line ri-lg"></i>)}
      </div>
      <div className="text-md text-center font-link font-bold drop-shadow">{ blurb }</div>
    </div>
  )
}

export default PartnerSection