import React from "react"

import DownloadNow from './DownloadNow'

const title = "REAL-TIME FEEDBACK ON LOAD TIMES, ROUTE TIMES, AND DELIVERY STATUS"
const tagline = "Streamlined radle to grave approach for your dispatch, asset, and billing needs."

const Hero = () => {
  return (
      <div className="my-16 flex flex-col items-center justify-center bg-contain bg-no-repeat bg-right bg-logotruck text-[rgb(247,247,247)] font-link">
        <div className="mx-4 mb-12 w-1/2 text-3xl font-black drop-shadow-2xl">{ title }</div>
        <div className="mx-4 w-1/2 text-lg font-black drop-shadow-2xl">{ tagline }</div>
        <DownloadNow />
      </div>
  )
}

export default Hero