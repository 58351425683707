import React from "react"

const Container = ({children}) => {
  return (
    <div className="py-4 overscroll-none h-full min-h-screen bg-gradient-to-b from-[#273C63] to-[#2B2E58]">
      {children}
    </div>
  )
}

export default Container