import React from "react"

import exodus from './assets/exodus.png'

const NavigationBar = () => {
  return (
    <div className="p-4 h-16 flex flex-row items-center justify-center bg-white">
      <img className="object-scale-down h-12" src={exodus} alt="Exodus logo" />
    </div>
  )
}

export default NavigationBar