import React from "react"

const ContentCard = ({body}) => {
  return (
    <div className="p-4 lg:w-1/5 bg-white text-[rgb(22,45,73)] font-link">
      <div className="flex justify-center text-[rgb(86,170,211)]">
        <i className="ri-checkbox-circle-line ri-2x"></i>
      </div>
      <div className="font-normal text-center" >{body}</div>
    </div>
  )
}

export default ContentCard