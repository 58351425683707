import React from "react"

const DownloadAppSection = () => {

  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  const httpsAppStore = "https://apps.apple.com/us/app/exodus-logics/id1643986154"
  const itmssAppStore = "itmss://apps.apple.com/us/app/exodus-logics/id1643986154"
  const playStore = "https://play.google.com/store/apps/details?id=com.exoduslogics.exodusandroid"

  return (
    <div id="downloadAppSection" className="mt-8 mx-auto w-1/2 flex flex-col items-center font-link text-xl text-[rgb(247,247,247)]">
      <div className="text-center">Download the driver mobile app at the App Store or the Play Store</div>
      <div className="mt-4 mb-32 flex flex-row gap-2">
        { (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) ? 
          <a href={itmssAppStore} target="_blank" rel="noopener noreferrer"><i class="ri-apple-fill ri-2x"></i></a> :
          <a href={httpsAppStore} target="_blank" rel="noopener noreferrer"><i class="ri-apple-fill ri-2x"></i></a>
        }
        <a href={playStore} target="_blank" rel="noopener noreferrer"><i class="ri-android-fill ri-2x"></i></a>
      </div>
    </div>
  )
}

export default DownloadAppSection