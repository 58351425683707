import React from "react"

import Container          from '../components/Container'
import Hero               from '../components/Hero'
import OneCardSection     from '../layout/OneCardSection'
import ThreeCardSection   from '../layout/ThreeCardSection'
import DownloadAppSection from '../layout/DownloadAppSection'
import PartnerSection     from '../layout/PartnerSection'
import NavigationBar      from '../layout/NavigationBar'

const Home = () => {
  return (
    <>
      <NavigationBar />
      <Container>
        <Hero />
        <OneCardSection />
        <PartnerSection />
        <ThreeCardSection />
        <DownloadAppSection />
      </Container>
    </>
  );
}

export default Home;