import React from "react"
import ContentCard from '../components/ContentCard'

const title = "LIVE LOAD PERFORMANCE"

const contentCards = [
  {
    body: "Autonomous, machine learning platform",
    key: 0
  },
  {
    body: "Safer, more efficient driver allocation",
    key: 1
  },
  {
    body: "Auto dispatch and increased efficiency",
    key: 2
  }
]

const ThreeCardSection = () => {
  return (
    <div className="p-8 bg-white text-[rgb(22,45,73)] font-link">
      <div className="text-3xl text-center font-black">{title}</div>
      <div className="flex justify-center items-center flex-col lg:flex-row gap-4">
        {contentCards.map(item => {return <ContentCard {...item} />})}
      </div>
    </div>

  )
}

export default ThreeCardSection