import { HashLink } from "react-router-hash-link"
import React from "react"

const DownloadNow = ({children}) => {

  const appStore = "itmss://apps.apple.com/us/app/exodus-logics/id1643986154/"
  const playStore = "https://play.google.com/store/apps/details?id=com.exoduslogics.exodusandroid"

  const redirect = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return (<a href={appStore}>Download Now</a>)
      } else if (/android/i.test(userAgent)) {
        return (<a href={playStore}>Download Now</a>)
      } else {
        return (<HashLink to="/#downloadAppSection">Download Now</HashLink>)
      }
  }

  return (
    <span className="m-4 py-4 px-12 inline-block rounded-full text-xl font-bold bg-[rgb(153,196,91)] text-[rgb(247,247,247)] font-link">
      {redirect()}
    </span>
  )
}

export default DownloadNow