import React from "react"
import phones from "./assets/phones.png"

const title = "CUSTOMER, CARRIER, AND DRIVER VISIBILITY"
const body = "Shift reports sent every 12 hours with total loads, drivers per shift per carrier and driver efficicency forrespective shift."

const OneCardSection = () => {
  return (
    <div className="pt-16 pb-12 lg:pb-2 grid lg:grid-cols-2 bg-white text-[rgb(22,45,73)] font-link">
      <img className="mx-auto lg:items-right" src={phones} alt="phones" />
      <div className="grid grid-cols-1 items-center">
        <div>
          <div className="text-center lg:text-left pb-4 mx-4 lg:mr-4 text-3xl font-black">{ title }</div>
          <div className="text-center lg:text-left mx-4 lg:mr-4 font-normal">{ body }</div>
        </div>
      </div>
    </div>
  )
}

export default OneCardSection